import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getAuth, deleteUser } from "firebase/auth";


import loadingIcon from './img/loading-icon.png'; // TODO:
import avatar1 from './img/sample/avatar/avatar-img-1.png';
import avatar2 from './img/sample/avatar/avatar2.jpg';
import avatar3 from './img/sample/avatar/avatar3.jpg';
import avatar4 from './img/sample/avatar/avatar4.jpg';
import imgX from './img/icon/192x192.png';

import '@splidejs/react-splide/css';
import './bootstrap.min.css';
import './ionicons.min.css';
import './index.css';


// Routing Stuff
// console.log("WHAT IS WINDOW.LOCATION:", )
const IS_DEV = window.location.href.toLocaleLowerCase().includes("getmubarak.com") ? false : true

const BASE_URL_DEV = 'http://localhost:5000/'
const BASE_URL_PROD = 'https://app.getMubarak.com/'
const BASE_URL = IS_DEV ? BASE_URL_DEV : BASE_URL_PROD

const BASE_URL_API_DEV = 'http://localhost:5001/financial-app-76849/us-central1/app/'
const BASE_URL_API_PROD = 'https://us-central1-financial-app-76849.cloudfunctions.net/app/'
const BASE_URL_API = IS_DEV ? BASE_URL_API_DEV : BASE_URL_API_PROD

const LOGIN_URL = BASE_URL + "login/"
const CREATE_USER_URL = BASE_URL_API + "create-user/"

const config = {
    "apiKey":"AIzaSyAdL6yvXQ38knnddV19kK3bq-PqiPCnL0o",
    "authDomain":"financial-app-76849.firebaseapp.com"
};
firebase.initializeApp(config);  

const signOut = () => {
    firebase.auth().signOut().then(() => {
        // alert("Successfully signed out")
    }).catch(() => {
        // alert("Error Signing Out")
    })
}

const createUserInDB = (userInfo) => {
    fetch(CREATE_USER_URL, {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json, text/plain, */*',
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({userInfo: userInfo})
    })
    .then(res => {
        return res.json();
    })
    .then(data => {

        setTimeout(() => {

            window.location.href = BASE_URL
        }, 1000)
    });
}


// Firebase Auth Stuff
var uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.AppleAuthProvider.PROVIDER_ID,
        // firebase.auth.YahooAuthProvider.PROVIDER_ID,
    ],
    signInSuccessUrl: BASE_URL,
    callbacks: {
        // signInSuccess: function(currentUser, credential, redirectUrl) {
        //     redirectUrl = BASE_URL;
        //     return false
        //   },
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
            const userId = authResult.user.uid
            var userInfo = authResult.additionalUserInfo;
            userInfo['userId'] = userId

            if (userInfo.isNewUser) {
                createUserInDB(userInfo)

            } else {
                alert("Please use the Login page")
                if (authResult.user) {
                    deleteUser(authResult.user).then(() => {
                        // User deleted.
                    }).catch((error) => {
                        // An error ocurred
                        // ...
                    });
                    signOut()
                    setTimeout(() => {
                        window.location.href = LOGIN_URL
                    }, 1000)
                }

            }

            if (userInfo.isNewUser && userInfo.providerId === "password") {
                try {
                    authResult.user.sendEmailVerification()
                    console.log("Check your email")
                } catch(e) {
                    console.log("Unable to send email", e)
                }
            }

            console.log("authResult", authResult)
            // setTimeout(() => {
            //     window.location.href = BASE_URL
            // }, 1000)
            // return true;
        }
    }
}



function Signup() {
    const [termsClicked, setTermsClicked] = useState(false);

    useEffect(() => {

    }, [])

    return (
        <div className="body">
            {/* <!-- loader --> */}
            <div id="loader">
                <img src={loadingIcon} alt="icon" className="loading-icon" onLoad={() => { 
                    setTimeout(() => {
                    document.getElementById('loader').remove()
                    }, 1500) 
                }} />
            </div>
            {/* <!-- * loader --> */}

            {/* <!-- App Header --> */}
            <div className="appHeader">
                {/* <div className="left">
                    <a href="#" className="headerButton" data-bs-toggle="modal" data-bs-target="#sidebarPanel">
                        <ion-icon name="menu-outline"></ion-icon>
                    </a>
                </div> */}
                <div className="right">
                    <Link to='/login'>
                        Login
                    </Link>
                </div>
            </div>
            {/* <!-- * App Header --> */}


            {/* <!-- App Capsule --> */}
            <div id="appCapsule">
                <div class="section mt-2 text-center">
                    <h1>Register now</h1>
                    <h4>Create an account</h4>
                </div>
                <div class="section mb-5 p-2">
                    <form action="index.html">
                        <div class="card">
                            <div class="card-body" >
                                {/* <div class="form-group basic"> */}
                                    {/* <div class="input-wrapper">
                                        <label class="label" for="email1">E-mail</label>
                                        <input type="email" class="form-control" id="email1" placeholder="Your e-mail" />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div> */}

                                    
                                {/* </div> */}

                                {/* <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="password1">Password</label>
                                        <input type="password" class="form-control" id="password1" autocomplete="off"
                                            placeholder="Your password" />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="password2">Password Again</label>
                                        <input type="password" class="form-control" id="password2" autocomplete="off"
                                            placeholder="Type password again" />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div> */}

                                {termsClicked && <div >
                                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                                </div>}

                                <div class="custom-control custom-checkbox mt-2 mb-1">
                                    <div class="form-check">
                                        <input type="checkbox" onClick={() => {setTermsClicked(!termsClicked)}} class="form-check-input" id="customCheckb1" />
                                        <label class="form-check-label" for="customCheckb1">
                                            I agree to the <a href="#" data-bs-toggle="modal" data-bs-target="#termsModal" className="orange-terms">terms and
                                                conditions</a>
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* <div class="form-button-group transparent">
                            <button type="submit" class="btn btn-primary btn-block btn-lg">Register</button>
                        </div> */}

                    </form>
                </div>
            </div>
            {/* <!-- * App Capsule --> */}


            {/* <!-- Terms Modal --> */}
            <div class="modal fade modalbox" id="termsModal" tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Terms and Conditions</h5>
                            <a href="#" data-bs-dismiss="modal">Close</a>
                        </div>
                        <div class="modal-body">
                            {/* <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc fermentum, urna eget finibus
                                fermentum, velit metus maximus erat, nec sodales elit justo vitae sapien. Sed fermentum
                                varius erat, et dictum lorem. Cras pulvinar vestibulum purus sed hendrerit. Praesent et
                                auctor dolor. Ut sed ultrices justo. Fusce tortor erat, scelerisque sit amet diam rhoncus,
                                cursus dictum lorem. Ut vitae arcu egestas, congue nulla at, gravida purus.
                            </p>
                            <p>
                                Donec in justo urna. Fusce pretium quam sed viverra blandit. Vivamus a facilisis lectus.
                                Nunc non aliquet nulla. Aenean arcu metus, dictum tincidunt lacinia quis, efficitur vitae
                                dui. Integer id nisi sit amet leo rutrum placerat in ac tortor. Duis sed fermentum mi, ut
                                vulputate ligula.
                            </p>
                            <p>
                                Vivamus eget sodales elit, cursus scelerisque leo. Suspendisse lorem leo, sollicitudin
                                egestas interdum sit amet, sollicitudin tristique ex. Class aptent taciti sociosqu ad litora
                                torquent per conubia nostra, per inceptos himenaeos. Phasellus id ultricies eros. Praesent
                                vulputate interdum dapibus. Duis varius faucibus metus, eget sagittis purus consectetur in.
                                Praesent fringilla tristique sapien, et maximus tellus dapibus a. Quisque nec magna dapibus
                                sapien iaculis consectetur. Fusce in vehicula arcu. Aliquam erat volutpat. Class aptent
                                taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
                            </p> */}

                            <iframe width="890" height="700" src="https://www.getmubarak.com/terms-of-service" frameborder="0" allowfullscreen></iframe>


                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- * Terms Modal --> */}

            {/* <!-- App Sidebar --> */}
            <div className="modal fade panelbox panelbox-left" id="sidebarPanel" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            {/* <!-- profile box --> */}
                            <div className="profileBox pt-2 pb-2">
                                <div className="image-wrapper">
                                    <img src={avatar1} alt="image" className="imaged  w36" />
                                </div>
                                <div className="in">
                                    <strong>Sebastian Doe</strong>
                                    <div className="text-muted">4029209</div>
                                </div>
                                <a href="#" className="btn btn-link btn-icon sidebar-close" data-bs-dismiss="modal">
                                    <ion-icon name="close-outline"></ion-icon>
                                </a>
                            </div>
                            {/* <!-- * profile box --> */}
                            {/* <!-- balance --> */}
                            <div className="sidebar-balance">
                                <div className="listview-title">Balance</div>
                                <div className="in">
                                    <h1 className="amount">$ 2,562.50</h1>
                                </div>
                            </div>
                            {/* <!-- * balance --> */}

                            {/* <!-- action group --> */}
                            <div className="action-group">
                                <a href="index.html" className="action-button">
                                    <div className="in">
                                        <div className="iconbox">
                                            <ion-icon name="add-outline"></ion-icon>
                                        </div>
                                        Deposit
                                    </div>
                                </a>
                                <a href="index.html" className="action-button">
                                    <div className="in">
                                        <div className="iconbox">
                                            <ion-icon name="arrow-down-outline"></ion-icon>
                                        </div>
                                        Withdraw
                                    </div>
                                </a>
                                <a href="index.html" className="action-button">
                                    <div className="in">
                                        <div className="iconbox">
                                            <ion-icon name="arrow-forward-outline"></ion-icon>
                                        </div>
                                        Send
                                    </div>
                                </a>
                                <a href="app-cards.html" className="action-button">
                                    <div className="in">
                                        <div className="iconbox">
                                            <ion-icon name="card-outline"></ion-icon>
                                        </div>
                                        My Cards
                                    </div>
                                </a>
                            </div>
                            {/* <!-- * action group --> */}

                            {/* <!-- menu --> */}
                            <div className="listview-title mt-1">Menu</div>
                            <ul className="listview flush transparent no-line image-listview">
                                <li>
                                    <a href="index.html" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="pie-chart-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Overview
                                            <span className="badge badge-primary">10</span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="app-pages.html" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="document-text-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Pages
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="app-components.html" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="apps-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Components
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="app-cards.html" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="card-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            My Cards
                                        </div>
                                    </a>
                                </li>
                            </ul>
                            {/* <!-- * menu --> */}

                            {/* <!-- others --> */}
                            <div className="listview-title mt-1">Others</div>
                            <ul className="listview flush transparent no-line image-listview">
                                <li>
                                    <a href="app-settings.html" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="settings-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Settings
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="component-messages.html" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="chatbubble-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Support
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="app-login.html" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="log-out-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Log out
                                        </div>
                                    </a>
                                </li>


                            </ul>
                            {/* <!-- * others --> */}

                            {/* <!-- send money --> */}
                            <div className="listview-title mt-1">Send Money</div>
                            <ul className="listview image-listview flush transparent no-line">
                                <li>
                                    <a href="#" className="item">
                                        <img src={avatar2} alt="image" className="image" />
                                        <div className="in">
                                            <div>Artem Sazonov</div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="item">
                                        <img src={avatar4} alt="image" className="image" />
                                        <div className="in">
                                            <div>Sophie Asveld</div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="item">
                                        <img src={avatar3} alt="image" className="image" />
                                        <div className="in">
                                            <div>Kobus van de Vegte</div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                            {/* <!-- * send money --> */}

                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- * App Sidebar --> */}



            {/* <!-- iOS Add to Home Action Sheet --> */}
            <div className="modal inset fade action-sheet ios-add-to-home" id="ios-add-to-home-screen" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add to Home Screen</h5>
                            <a href="#" className="close-button" data-bs-dismiss="modal">
                                <ion-icon name="close"></ion-icon>
                            </a>
                        </div>
                        <div className="modal-body">
                            <div className="action-sheet-content text-center">
                                <div className="mb-1">
                                    <img src={imgX} alt="image" className="imaged w64 mb-2" />
                                </div>
                                <div>
                                    Install <strong>Mubarak</strong> on your iPhone's home screen.
                                </div>
                                <div>
                                    Tap <ion-icon name="share-outline"></ion-icon> and Add to homescreen.
                                </div>
                                <div className="mt-2">
                                    <button className="btn btn-primary btn-block" data-bs-dismiss="modal">CLOSE</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- * iOS Add to Home Action Sheet --> */}


            {/* <!-- Android Add to Home Action Sheet --> */}
            <div className="modal inset fade action-sheet android-add-to-home" id="android-add-to-home-screen" tabIndex="-1"
                role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add to Home Screen</h5>
                            <a href="#" className="close-button" data-bs-dismiss="modal">
                                <ion-icon name="close"></ion-icon>
                            </a>
                        </div>
                        <div className="modal-body">
                            <div className="action-sheet-content text-center">
                                <div className="mb-1">
                                    <img src={imgX} alt="image" className="imaged w64 mb-2" />
                                </div>
                                <div>
                                    Install <strong>Mubarak</strong> on your Android's home screen.
                                </div>
                                <div>
                                    Tap <ion-icon name="ellipsis-vertical"></ion-icon> and Add to homescreen.
                                </div>
                                <div className="mt-2">
                                    <button className="btn btn-primary btn-block" data-bs-dismiss="modal">CLOSE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- * Android Add to Home Action Sheet --> */}



            <div id="cookiesbox" className="offcanvas offcanvas-bottom cookies-box" tabIndex="-1" data-bs-scroll="true"
                data-bs-backdrop="false">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title">We use cookies</h5>
                </div>
                <div className="offcanvas-body">
                    <div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla non lacinia quam. Nulla facilisi.
                        <a href="#" className="text-secondary"><u>Learn more</u></a>
                    </div>
                    <div className="buttons">
                        <a href="#" className="btn btn-primary btn-block" data-bs-dismiss="offcanvas">I understand</a>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Signup;
