import React, { useEffect, useState } from 'react';

import useAuth from "./useAuth";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { usePlaidLink, PlaidLinkOptions, PlaidLinkOnSuccess } from 'react-plaid-link';  
import { Widget } from '@typeform/embed-react'

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getAuth, deleteUser } from "firebase/auth";
import 'firebase/compat/firestore';


// import logo from './logo.svg';
import loadingIcon from './img/loading-icon.png'; // TODO:
// import avatar1 from './img/sample/avatar/avatar1.jpg';
import avatar1 from './img/sample/avatar/avatar-img-1.png';
import avatar2 from './img/sample/avatar/avatar2.jpg';
import avatar3 from './img/sample/avatar/avatar3.jpg';
import avatar4 from './img/sample/avatar/avatar4.jpg';
import avatar5 from './img/sample/avatar/avatar5.jpg';
import avatar6 from './img/sample/avatar/avatar6.jpg';
import avatar7 from './img/sample/avatar/avatar7.jpg';
import avatar8 from './img/sample/avatar/avatar8.jpg';
import avatar9 from './img/sample/avatar/avatar9.jpg';
import avatar10 from './img/sample/avatar/avatar10.jpg';
import brand0 from "./img/sample/brand/0.png";
import brand1 from "./img/sample/brand/1.jpg";
import brand2 from "./img/sample/brand/2.jpg";
import appStore from "./img/sample/brand/app-store-transaction.png";
import card from "./img/sample/brand/card.png";
import food from "./img/sample/brand/food.png";
import bank from "./img/sample/brand/bank.png";
import sendMoney from "./img/sample/brand/send-money.png";
import shopping from "./img/sample/brand/shopping.png";
import transfer from "./img/sample/brand/transfer.png";
import travel from "./img/sample/brand/travel1.png";
import taxi from "./img/sample/brand/taxi.png";
import health from "./img/sample/brand/health.png";
import netflix from "./img/sample/brand/netflix.png";
import spotify from "./img/sample/brand/spotify.png";

import photo1 from './img/sample/photo/1.jpg';
import photo2 from './img/sample/photo/2.jpg';
import photo3 from './img/sample/photo/3.jpg';
import photo4 from './img/sample/photo/4.jpg';
import imgX from './img/icon/192x192.png';
// import './App.css'; // Leftover from create-react-app

import '@splidejs/react-splide/css';
import './bootstrap.min.css';
import './ionicons.min.css';
// import './splide.min.css';
import './index.css';

// Routing Stuff
// console.log("WHAT IS WINDOW.LOCATION:", )
const IS_DEV = window.location.href.toLocaleLowerCase().includes("getmubarak.com") ? false : true

const BASE_URL_DEV = 'http://localhost:5001/financial-app-76849/us-central1/app/'
const BASE_URL_PROD = 'https://us-central1-financial-app-76849.cloudfunctions.net/app/'
// https://us-central1-financial-app-76849.cloudfunctions.net/app -> api.getmubarak.com
const BASE_URL = IS_DEV ? BASE_URL_DEV : BASE_URL_PROD

const TRANSACTIONS_URL = BASE_URL + "transactions/"
const TRANSACTIONS2_URL = BASE_URL + "transactions2/"
// Really, transactions2 should be called demoTransactions to represent it's what the user sees when they are in demo mode, ie logged in, but no bank account linked
const GENERATE_LINK_TOKEN_URL = BASE_URL + "generate-link-token/"
const GENERATE_SANDBOX_PUBLIC_TOKEN_URL = BASE_URL + "generate-sandbox-public-token/"
const EXCHANGE_PUBLIC_TOKEN_URL = BASE_URL + "exchange-public-token/"
const GET_ACCOUNTS_URL = BASE_URL + "get-accounts/"
const GET_BILLS_URL = BASE_URL + "get-recurring/"
const AUTH_URL = BASE_URL + "auth/"


// Helpers
const CURRENCY_CODE_SYMBOL_MAP = {
    "USD": "$"
}

function Loader() {}
function Header() {}
function WalletCard() {}
function Stats() {}
function Transactions() {}
function Footer() {}
function AppMenuBottom() {}

function App() {
    const { user } = useAuth();
    // user?.displayName, user?.photoURL

    const [transactions, setTransactions] = useState([])
    const [plaidAuth, setPlaidAuth] = useState(null)

    const [linkToken, setLinkToken] = useState(null)
    const [publicToken, setPublicToken] = useState(null)
    const [accessToken, setAccessToken] = useState(null)

    console.log("App.js user", user)
    console.log("App.js user.uid", user?.uid)
    console.log("App.js user?.displayName", user?.displayName)
    console.log("App.js user?.photoURL", user?.photoURL)

    // Which of these mean I have credentials? 
    // Which one is such that if I am missing it, I need to show the login button?

    console.log("App")

    // TODO: have a separate ConnectBank component that encourages user to connect bank accounts
    // This should not go on App.js except maybe to add an extra bank account
    // This component
    const plaidConfig = {
        onSuccess: (public_token, metadata) => { 
            console.log("Plaid onSuccess", public_token, metadata) 
            // Maybe the move is to save public_token via a backend endpoint under uuid: public_token: token
            // This way I can keep track of when someone connected their account or not.
            // I should also save this on localStorage to cache off whether they connected or not

            // TODO: I should also have a disconnect method which can be manual for now

            // metadata has institution, account, account type, etc


            setPublicToken(public_token)
            // HERE
            // HERE
            // Exchange public_token for access_token
            try {
                console.log("About to exchange public token for access token")
                fetch(EXCHANGE_PUBLIC_TOKEN_URL, {
                    method: 'POST',
                    mode: 'cors', // no-cors, *cors, same-origin
                    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    // credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json, text/plain, */*',
                        'uid': user?.uid
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },             
                    body: JSON.stringify({public_token: public_token})
                })
                .then(res => {
                    return res.json();
                })
                .then(data => {
                    console.log("exchange-public-token/ endpoint")
                    console.log('data:: ', data)
                    console.log(data['access_token'])

                    setAccessToken(data['access_token'])
                });
            } catch {
                console.log("!!! Failed to exchange public token for access token") 
            }
            
        },
        onExit: (err, metadata) => { console.log("Plaid onExit", err, metadata) },
        onEvent: (eventName, metadata) => { console.log("Plaid onEvent", eventName, metadata) },
        token: linkToken,
    }

    console.log("------------")
    console.log("linkToken is currently ", linkToken)
    console.log("publicToken is currently ", publicToken)
    console.log("accessToken is currently ", accessToken)

    const { open, exit, ready } = usePlaidLink(plaidConfig);

    const connectBankAccount = (e) => {
        // The goal of this function is to connect bank account to generate a Plaid public-token
        // In dev, prod this is done via launching the Link bank account auth flow
        // In sandbox we call generate-sandbox-public-token to get a public-token
        // TODO: Should I save this public-token somewhere permanent? 

        if (linkToken === null) {
            console.log("ERROR: linkToken is null")
            return

        } else if (linkToken === '***SANDBOX***') {
            console.log("Using fake sandbox linkToken")

            // Fetch sandbox public-token
            try {
                console.log("About to generate sandbox public token")
                fetch(GENERATE_SANDBOX_PUBLIC_TOKEN_URL, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json, text/plain, */*',
                        'uid': user?.uid
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                })
                .then(res => {
                    return res.json();
                })
                .then(data => {
                    console.log("generate-sandbox-public-token/ endpoint")
                    console.log(data['public_token'])

                    setPublicToken(data['public_token'])
                    // TODO: Hit EXCHANGE_PUBLIC_TOKEN_URL
                });
            } catch {
                console.log("!!! Failed to generate sandbox public token") 
            }
 
            return
        } else {
            open()

        }
    }

    useEffect(() => {
        // Fetch Transactions
        try {
            console.log("About to fetch transactions")
            fetch(TRANSACTIONS_URL, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json, text/plain, */*',
                    'uid': user?.uid
                },       
                // body: JSON.stringify({"hellooo": "woooold"})
            })
            .then(res => {
                return res.json();
            })
            .then(data => {
                console.log("transactions/ endpoint")
                console.log(data)
                setTransactions(data['data'])
            });
        } catch {
            console.log("!!! Failed to get transactions")
        }

        // TODO: When should this be called? On click of Connect Bank Account?
        // Fetch temp_link_token
        try {
            console.log("About to generate link token")
            fetch(GENERATE_LINK_TOKEN_URL, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json, text/plain, */*',
                    'uid': user?.uid
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .then(res => {
                return res.json();
            })
            .then(data => {
                console.log("create-link-token/ endpoint")
                console.log(data['link_token'])

                // TODO: handle if data is None or the backend messes up
                setLinkToken(data['link_token'])
            });
        } catch {
            console.log("!!! Failed to generate link token") 
        }



        // Fetch bills data
        // try {
        //     console.log("About to fetch bills")
        //     fetch(GET_BILLS_URL)
        //     .then(res => {
        //         return res.json();
        //     })
        //     .then(data => {
        //         console.log("get-recurring/ endpoint")
        //         console.log(data)

        //         // TODO: handle if data is None or the backend messes up
        //         // setLinkToken(data['link_token'])
        //     });
        // } catch {
        //     console.log("!!! Failed to get-accounts") 
        // }

        // Fetch Transactions
        // try {
        //     console.log("About to fetch transactions")
        //     fetch(TRANSACTIONS_URL)
        //     .then(res => {
        //         return res.json();
        //     })
        //     .then(data => {
        //         console.log("transactions/ endpoint")
        //         console.log(data)
        //         // setTransactions(data['data'])
        //     });
        // } catch {
        //     console.log("!!! Failed to get transactions")
        // }
        
        // Fetch Transactions2
        // try {
        //     console.log("About to fetch transactions2")
        //     fetch(TRANSACTIONS2_URL)
        //     .then(res => {
        //         return res.json();
        //     })
        //     .then(data => {
        //         console.log("transactions2/ endpoint")
        //         console.log(data)
        //         setTransactions(data['data'])
        //     });
        // } catch {
        //     console.log("!!! Failed to get transactions2")
        // }

        // TODO: only try this if bank account connected and access token stored
        // TODO: store accessToken on frontend in localStorage to represent bank connected
        // If no accessToken, check bankAccountConnected endpoint and show Could not Connect, try again error
        // if access token, check auth endpoint.
        // if no auth, don't allow lending until auth
        // if auth, has lending
        // else show demo mode

        // Fetch Auth
        try {
            console.log("About to fetch auth")
            fetch(AUTH_URL, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json, text/plain, */*',
                    'uid': user?.uid
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .then(res => {
                return res.json();
            })
            .then(data => {
                console.log("auth/ endpoint")
                console.log("Auth data")
                console.log(data)
                setPlaidAuth(data)
            });
        } catch {
            console.log("!!! Failed to get auth")
        }

        
    }, [])

    const getCategoryIconFromCategoryList = (categoryList) => {
        if (categoryList.includes("Travel") && categoryList.includes("Airlines and Aviation Services")) {
            return travel
        }
        else if (categoryList.includes("Travel") && categoryList.includes("Taxi")) {
            return taxi
        }
        else if (categoryList.includes("Payment")) {
            return card
        }
        else if (categoryList.includes("Transfer")) {
            return sendMoney
        }
        else if (categoryList.includes("Food and Drink")) {
            return food
        }
        // else if (categoryList.includes("Travel")) {
        //     return ("airplace")
        // }
        // else if (categoryList.includes("Travel")) {
        //     return ("airplace")
        // }
        // else if (categoryList.includes("Travel")) {
        //     return ("airplace")
        // } 
        else {
            return brand0
        }
    }

    return (
        <div className="body">
            {/* <!-- loader --> */}
            <div id="loader">
                <img src={loadingIcon} alt="icon" className="loading-icon" onLoad={() => { 
                    setTimeout(() => {
                    document.getElementById('loader').remove()
                    }, 1500) 
                }} />
            </div>
            {/* <!-- * loader --> */}

            {/* <!-- App Header --> */}
            <div className="appHeader bg-primary text-light">
                <div className="left">
                    <a href="#" className="headerButton" data-bs-toggle="modal" data-bs-target="#sidebarPanel">
                        <ion-icon name="menu-outline"></ion-icon>
                    </a>
                </div>
                <div className="pageTitle">
                    {/* <a className="mubarak-logo-text">Mubarak</a> */}
                    <Link className="mubarak-logo-text" to="/">
                        <a className="mubarak-logo-text">Mubarak</a>
                    </Link>
                </div>
                <div className="right">
                    {/* <Link className='headerButton' to="/notifications">
                        <ion-icon className="icon" name="notifications-outline"></ion-icon> */}
                        {/* <span className="badge badge-danger">4</span> */}
                    {/* </Link> */}
                    <Link className='link profile' to="/profile">
                        <img src={avatar1} alt="image" className="imaged w32" />
                        {/* <img src={user?.photoURL ? user?.photoURL : avatar1} alt="image" className="imaged w32" /> */}
                        {/* <span className="badge badge-danger">6</span> */}
                    </Link>
                </div>
            </div>
            {/* <!-- * App Header --> */}


            {/* <!-- App Capsule --> */}
            <div id="appCapsuleApp">

                {/* <!-- Wallet Card --> */}
                <div className="section wallet-card-section pt-1">
                    <div className="wallet-card">
                        {/* <!-- Balance --> */}
                        <div className="balance">
                            <div className="left">
                                <span className="title">Total Balance</span>
                                <h1 className="total">$ 2,562.50</h1>
                            </div>
                            <div className="right">
                                <a href="#" className="button" data-bs-toggle="modal" data-bs-target="#depositActionSheet">
                                    <ion-icon name="add-outline"></ion-icon>
                                </a>
                            </div>
                        </div>
                        {/* <!-- * Balance --> */}
                        {/* <!-- Wallet Footer --> */}
                        <div className="wallet-footer">
                            <div className="item" onClick={connectBankAccount}>
                                <a href="#" data-bs-toggle="modal" data-bs-target="#withdrawActionSheet">
                                    <div className="icon-wrapper bg-danger">
                                        <ion-icon name="arrow-down-outline"></ion-icon>
                                    </div>
                                    <strong>Withdraw</strong>
                                </a>
                            </div>
                            <div className="item">
                                <a href="#" data-bs-toggle="modal" data-bs-target="#sendActionSheet">
                                    <div className="icon-wrapper">
                                        <ion-icon name="arrow-forward-outline"></ion-icon>
                                    </div>
                                    <strong>Send</strong>
                                </a>
                            </div>
                            {/* <div className="item"> */}
                                {/* <a href="app-cards.html"> */}
                                {/* <a href="#">
                                    <div className="icon-wrapper bg-success">
                                        <ion-icon name="card-outline"></ion-icon>
                                    </div>
                                    <strong>Cards</strong>
                                </a>
                            </div> */}
                            <div className="item">
                                <a href="#" data-bs-toggle="modal" data-bs-target="#exchangeActionSheet">
                                    <div className="icon-wrapper bg-warning">
                                        <ion-icon name="swap-vertical"></ion-icon>
                                    </div>
                                    <strong>Exchange</strong>
                                </a>
                            </div>

                        </div>
                        {/* <!-- * Wallet Footer --> */}
                    </div>
                </div>
                {/* <!-- Wallet Card --> */}


                {/* <!-- Transactions --> */}
                {/* Should be it's own component */}
                <div className="section mt-4">
                    <div className="transactions">
                        {/* <!-- item --> */}
                            <div className="item">
                                <div className="detail">
                                    <img src={bank} alt="img" className="image-block imaged w48" onClick={connectBankAccount} />
                                    <div>
                                        <strong>
                                            This is a demo account
                                        </strong>
                                        <p>
                                            Connect your bank account<br />
                                        </p>
                                    </div>
                                </div>
                                <div className="right">

                                </div>
                            </div>
                    </div>
                </div>
                {/* <!-- * Transactions --> */}


                {/* <!-- Deposit Action Sheet --> */}
                <div className="modal fade action-sheet" id="depositActionSheet" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add Balance</h5>
                            </div>
                            <div className="modal-body">
                                <div className="action-sheet-content">
                                    <form>
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="label" >To</label>
                                                <select className="form-control custom-select" id="account1">
                                                    <option value="0">Savings (*** 5019)</option>
                                                    <option value="1">Investment (*** 6212)</option>
                                                    <option value="2">Mortgage (*** 5021)</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <label className="label">Enter Amount</label>
                                            <div className="input-group mb-2">
                                                <span className="input-group-text" id="basic-addona1">$</span>
                                                <input type="text" className="form-control" placeholder="Enter an amount" value="100" />
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <label className="label">Speed</label>
                                            <div className="input-group mb-2">
                                                <span className="input-group-text" id="basic-addona1">$</span>
                                                <input type="text" className="form-control" placeholder="Enter an amount" value="100" />
                                            </div>
                                        </div>


                                        <div className="form-group basic">
                                            <button type="button" className="btn btn-primary btn-block btn-lg"
                                                data-bs-dismiss="modal">Deposit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- * Deposit Action Sheet --> */}

                {/* <!-- Withdraw Action Sheet --> */}
                <div className="modal fade action-sheet" id="withdrawActionSheet" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Withdraw Money</h5>
                            </div>
                            <div className="modal-body">
                                <div className="action-sheet-content">
                                    <form>
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="label" >From</label>
                                                <select className="form-control custom-select" id="account2d">
                                                    <option value="0">Savings (*** 5019)</option>
                                                    <option value="1">Investment (*** 6212)</option>
                                                    <option value="2">Mortgage (*** 5021)</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="label" >To</label>
                                                <input type="email" className="form-control" id="text11d" placeholder="Enter IBAN" />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <label className="label">Enter Amount</label>
                                            <div className="input-group mb-2">
                                                <span className="input-group-text" id="basic-addonb1">$</span>
                                                <input type="text" className="form-control" placeholder="Enter an amount" value="100" />
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <button type="button" className="btn btn-primary btn-block btn-lg"
                                                data-bs-dismiss="modal">Withdraw</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- * Withdraw Action Sheet --> */}

                {/* <!-- Send Action Sheet --> */}
                <div className="modal fade action-sheet" id="sendActionSheet" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Send Money</h5>
                            </div>
                            <div className="modal-body">
                                <div className="action-sheet-content">
                                <Widget id="VKDPWRKH" style={{ width: '100%', height: '400px' }} className="my-form" />

                                    {/* <form>
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="label" >From</label>
                                                <select className="form-control custom-select" id="account2">
                                                    <option value="0">Savings (*** 5019)</option>
                                                    <option value="1">Investment (*** 6212)</option>
                                                    <option value="2">Mortgage (*** 5021)</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="label" >To</label>
                                                <input type="email" className="form-control" id="text11" placeholder="Enter bank ID" />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <label className="label">Enter Amount</label>
                                            <div className="input-group mb-2">
                                                <span className="input-group-text" id="basic-addon1">$</span>
                                                <input type="text" className="form-control" placeholder="Enter an amount" value="100" />
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <button type="button" className="btn btn-primary btn-block btn-lg"
                                                data-bs-dismiss="modal">Send</button>
                                        </div>
                                    </form> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- * Send Action Sheet --> */}

                {/* <!-- Exchange Action Sheet --> */}
                <div className="modal fade action-sheet" id="exchangeActionSheet" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Exchange Money</h5>
                            </div>
                            <div className="modal-body">
                                <div className="action-sheet-content">
                                <Widget id="Bip2EuQp" style={{ width: '100%', height: '400px' }} className="my-form" />
                                    {/* <form>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-group basic">
                                                    <div className="input-wrapper">
                                                        <label className="label" >From</label>
                                                        <select className="form-control custom-select" id="currency1">
                                                            <option value="1">EUR</option>
                                                            <option value="2">USD</option>
                                                            <option value="3">AUD</option>
                                                            <option value="4">CAD</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group basic">
                                                    <div className="input-wrapper">
                                                        <label className="label" >To</label>
                                                        <select className="form-control custom-select" id="currency2">
                                                            <option value="1">USD</option>
                                                            <option value="1">EUR</option>
                                                            <option value="2">AUD</option>
                                                            <option value="3">CAD</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <label className="label">Enter Amount</label>
                                            <div className="input-group mb-2">
                                                <span className="input-group-text" id="basic-addon2">$</span>
                                                <input type="text" className="form-control" placeholder="Enter an amount" value="100" />
                                            </div>
                                        </div>



                                        <div className="form-group basic">
                                            <button type="button" className="btn btn-primary btn-block btn-lg"
                                                data-bs-dismiss="modal">Exchange</button>
                                        </div>
                                    </form> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- * Exchange Action Sheet --> */}

                {/* <!-- Stats --> */}
                <div className="section">
                    <div className="row mt-2">
                        <div className="col-6">
                            <div className="stat-box">
                                <div className="title">Income</div>
                                <div className="value text-success">$ 552.95</div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="stat-box">
                                <div className="title">Expenses</div>
                                <div className="value text-danger">$ 86.45</div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-6">
                            <div className="stat-box">
                                <div className="title">Total Bills</div>
                                <div className="value">$ 53.25</div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="stat-box">
                                <div className="title">Savings</div>
                                <div className="value">$ 120.99</div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- * Stats --> */}

                {/* <!-- Transactions --> */}
                {/* Should be it's own component */}
                <div className="section mt-4">
                    <div className="section-heading">
                        <h2 className="title">Transactions</h2>
                        <Link className='link' to="/transactions">View All</Link>
                    </div>
                    <div className="transactions">
                        {/* <!-- item --> */}
                        {transactions && transactions.length > 0 && transactions.splice(1, 6).map((transaction) => {
                            return (
                                <Link className="item" to={`/transaction/${transaction['transaction_id']}`} key={transaction['transaction_id']}>
                                    <div className="detail">
                                        <img src={getCategoryIconFromCategoryList(transaction['category'])} alt="img" className="image-block imaged w48" />
                                        <div>
                                            <strong>
                                                {transaction['name']}
                                            </strong>
                                            <p>
                                                {transaction['category'].length > 0 ? transaction['category'][0] : ""}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className={`price ${transaction['amount'] > 0 ? 'text-danger' : 'text-success'}`}>
                                            {CURRENCY_CODE_SYMBOL_MAP[transaction['iso_currency_code']]}
                                            {-1 * transaction['amount']}
                                        </div>
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                </div>
                {/* <!-- * Transactions --> */}

                {/* <!-- my cards --> */}
                {/* <div className="section full mt-4">
                    <div className="section-heading padding">
                        <h2 className="title">My Cards</h2>
                        <a href="app-cards.html" className="link">View All</a>
                    </div>

                    <div className="carousel-single splide">
                        <div className="splide__track">
                            <ul className="splide__list">

                                <li className="splide__slide">
                                    <div className="card-block bg-primary">
                                        <div className="card-main">
                                            <div className="card-button dropdown">
                                                <button type="button" className="btn btn-link btn-icon" data-bs-toggle="dropdown">
                                                    <ion-icon name="ellipsis-horizontal"></ion-icon>
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                    <a className="dropdown-item" href="#">
                                                        <ion-icon name="pencil-outline"></ion-icon>Edit
                                                    </a>
                                                    <a className="dropdown-item" href="#">
                                                        <ion-icon name="close-outline"></ion-icon>Remove
                                                    </a>
                                                    <a className="dropdown-item" href="#">
                                                        <ion-icon name="arrow-up-circle-outline"></ion-icon>Upgrade
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="balance">
                                                <span className="label">BALANCE</span>
                                                <h1 className="title">$ 1,256,90</h1>
                                            </div>
                                            <div className="in">
                                                <div className="card-number">
                                                    <span className="label">Card Number</span>
                                                    •••• 9905
                                                </div>
                                                <div className="bottom">
                                                    <div className="card-expiry">
                                                        <span className="label">Expiry</span>
                                                        12 / 25
                                                    </div>
                                                    <div className="card-ccv">
                                                        <span className="label">CCV</span>
                                                        553
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li className="splide__slide">
                                    <div className="card-block bg-dark">
                                        <div className="card-main">
                                            <div className="card-button dropdown">
                                                <button type="button" className="btn btn-link btn-icon" data-bs-toggle="dropdown">
                                                    <ion-icon name="ellipsis-horizontal"></ion-icon>
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                    <a className="dropdown-item" href="#">
                                                        <ion-icon name="pencil-outline"></ion-icon>Edit
                                                    </a>
                                                    <a className="dropdown-item" href="#">
                                                        <ion-icon name="close-outline"></ion-icon>Remove
                                                    </a>
                                                    <a className="dropdown-item" href="#">
                                                        <ion-icon name="arrow-up-circle-outline"></ion-icon>Upgrade
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="balance">
                                                <span className="label">BALANCE</span>
                                                <h1 className="title">$ 1,256,90</h1>
                                            </div>
                                            <div className="in">
                                                <div className="card-number">
                                                    <span className="label">Card Number</span>
                                                    •••• 9905
                                                </div>
                                                <div className="bottom">
                                                    <div className="card-expiry">
                                                        <span className="label">Expiry</span>
                                                        12 / 25
                                                    </div>
                                                    <div className="card-ccv">
                                                        <span className="label">CCV</span>
                                                        553
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li className="splide__slide">
                                    <div className="card-block bg-secondary">
                                        <div className="card-main">
                                            <div className="card-button dropdown">
                                                <button type="button" className="btn btn-link btn-icon" data-bs-toggle="dropdown">
                                                    <ion-icon name="ellipsis-horizontal"></ion-icon>
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                    <a className="dropdown-item" href="#">
                                                        <ion-icon name="pencil-outline"></ion-icon>Edit
                                                    </a>
                                                    <a className="dropdown-item" href="#">
                                                        <ion-icon name="close-outline"></ion-icon>Remove
                                                    </a>
                                                    <a className="dropdown-item" href="#">
                                                        <ion-icon name="arrow-up-circle-outline"></ion-icon>Upgrade
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="balance">
                                                <span className="label">BALANCE</span>
                                                <h1 className="title">$ 1,256,90</h1>
                                            </div>
                                            <div className="in">
                                                <div className="card-number">
                                                    <span className="label">Card Number</span>
                                                    •••• 9905
                                                </div>
                                                <div className="bottom">
                                                    <div className="card-expiry">
                                                        <span className="label">Expiry</span>
                                                        12 / 25
                                                    </div>
                                                    <div className="card-ccv">
                                                        <span className="label">CCV</span>
                                                        553
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>

                </div> */}

                {/* <!-- Send Money --> */}
                {/* <div className="section full mt-4">
                    <div className="section-heading padding">
                        <h2 className="title">Send Money</h2>
                        <a href="#" className="link">Add New</a>
                    </div>

                    <div className="carousel-small splide">
                        <div className="splide__track">
                            <ul className="splide__list">
                                <li className="splide__slide">
                                    <a href="#">
                                        <div className="user-card">
                                            <img src={avatar2} alt="img" className="imaged w-100" />
                                            <strong>Jurrien</strong>
                                        </div>
                                    </a>
                                </li>
                                <li className="splide__slide">
                                    <a href="#">
                                        <div className="user-card">
                                            <img src={avatar3} alt="img" className="imaged w-100" />
                                            <strong>Elwin</strong>
                                        </div>
                                    </a>
                                </li>
                                <li className="splide__slide">
                                    <a href="#">
                                        <div className="user-card">
                                            <img src={avatar4} alt="img" className="imaged w-100" />
                                            <strong>Alma</strong>
                                        </div>
                                    </a>
                                </li>
                                <li className="splide__slide">
                                    <a href="#">
                                        <div className="user-card">
                                            <img src={avatar5} alt="img" className="imaged w-100" />
                                            <strong>Justine</strong>
                                        </div>
                                    </a>
                                </li>
                                <li className="splide__slide">
                                    <a href="#">
                                        <div className="user-card">
                                            <img src={avatar6} alt="img" className="imaged w-100" />
                                            <strong>Maria</strong>
                                        </div>
                                    </a>
                                </li>
                                <li className="splide__slide">
                                    <a href="#">
                                        <div className="user-card">
                                            <img src={avatar7} alt="img" className="imaged w-100" />
                                            <strong>Pamela</strong>
                                        </div>
                                    </a>
                                </li>
                                <li className="splide__slide">
                                    <a href="#">
                                        <div className="user-card">
                                            <img src={avatar8} alt="img" className="imaged w-100" />
                                            <strong>Neville</strong>
                                        </div>
                                    </a>
                                </li>
                                <li className="splide__slide">
                                    <a href="#">
                                        <div className="user-card">
                                            <img src={avatar9} alt="img" className="imaged w-100" />
                                            <strong>Alex</strong>
                                        </div>
                                    </a>
                                </li>
                                <li className="splide__slide">
                                    <a href="#">
                                        <div className="user-card">
                                            <img src={avatar10} alt="img" className="imaged w-100" />
                                            <strong>Stina</strong>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}
                {/* <!-- * Send Money --> */}

                {/* <!-- Monthly Bills --> */}
                <div className="section full mt-4">
                    <div className="section-heading padding">
                        <h2 className="title">Monthly Bills</h2>
                        <Link className='link' to="/bills">View All</Link>
                    </div>

                    <Splide options={{
                        snap: false,
                        arrows: false,
                        pagination: false,
                        drag: 'free',
                        perPage: 2,
                        gap: '15px',
                        padding: {left: 10, right: 25},
                    }} >
                        <SplideSlide>
                            <div className="bill-box">
                                <div className="img-wrapper">
                                    <img src={netflix} alt="img" className="image-block imaged w48" />
                                </div>
                                <div className="price">$ 14</div>
                                <p>Netflix Monthly Subscription</p>
                                <a href="#" className="btn btn-primary btn-block btn-sm">PAY NOW</a>
                            </div>
                        </SplideSlide>

                        <SplideSlide>
                            <div className="bill-box">
                                <div className="img-wrapper">
                                    <img src={spotify} alt="img" className="image-block imaged w48" />
                                </div>
                                <div className="price">$ 9</div>
                                <p>Spotify Monthly Subscription</p>
                                <a href="#" className="btn btn-primary btn-block btn-sm">PAY NOW</a>
                            </div>
                        </SplideSlide>

                        <SplideSlide>
                            <div className="bill-box">
                                <div className="img-wrapper">
                                    <img src={health} alt="img" className="image-block imaged w48" />                                </div>
                                <div className="price">$ 299</div>
                                <p>Monthly Health Insurance</p>
                                <a href="#" className="btn btn-primary btn-block btn-sm">PAY NOW</a>
                            </div>
                        </SplideSlide>

                        <SplideSlide>
                            <div className="bill-box">
                                <div className="img-wrapper">
                                    <img src={card} alt="img" className="image-block imaged w48" />
                                </div>
                                <div className="price">$ 962</div>
                                <p>Credit Card Statement
                                </p>
                                <a href="#" className="btn btn-primary btn-block btn-sm">PAY NOW</a>
                            </div>
                        </SplideSlide>
                    </Splide>
                </div>
                {/* <!-- Monthly Bills --> */}

                {/* <!-- Saving Goals --> */}
                {/* <div className="section mt-4">
                    <div className="section-heading">
                        <h2 className="title">Saving Goals</h2>
                        <a href="app-savings.html" className="link">View All</a>
                    </div>
                    <div className="goals"> */}
                        {/* <!-- item --> */}
                        {/* <div className="item">
                            <div className="in">
                                <div>
                                    <h4>Gaming Console</h4>
                                    <p>Gaming</p>
                                </div>
                                <div className="price">$ 499</div>
                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{width: '85%'}} aria-valuenow="85"
                                    aria-valuemin="0" aria-valuemax="100">85%</div>
                            </div>
                        </div> */}
                        {/* <!-- * item --> */}
                        {/* <!-- item --> */}
                        {/* <div className="item">
                            <div className="in">
                                <div>
                                    <h4>New House</h4>
                                    <p>Living</p>
                                </div>
                                <div className="price">$ 100,000</div>
                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{width: '55%'}} aria-valuenow="55"
                                    aria-valuemin="0" aria-valuemax="100">55%</div>
                            </div>
                        </div> */}
                        {/* <!-- * item --> */}
                        {/* <!-- item --> */}
                        {/* <div className="item">
                            <div className="in">
                                <div>
                                    <h4>Sport Car</h4>
                                    <p>Lifestyle</p>
                                </div>
                                <div className="price">$ 42,500</div>
                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{width: '15%'}} aria-valuenow="15"
                                    aria-valuemin="0" aria-valuemax="100">15%</div>
                            </div>
                        </div> */}
                        {/* <!-- * item --> */}
                    {/* </div>
                </div> */}
                {/* <!-- * Saving Goals --> */}


                {/* <!-- News --> */}
                {/* <div className="section full mt-4 mb-3">
                    <div className="section-heading padding">
                        <h2 className="title">Lastest News</h2>
                        <a href="app-blog.html" className="link">View All</a>
                    </div>

                    <div className="carousel-multiple splide">
                        <div className="splide__track">
                            <ul className="splide__list">

                                <li className="splide__slide">
                                    <a href="app-blog-post.html">
                                        <div className="blog-card">
                                            <img src={photo1} alt="image" className="imaged w-100" />
                                            <div className="text">
                                                <h4 className="title">What will be the value of bitcoin in the next...</h4>
                                            </div>
                                        </div>
                                    </a>
                                </li>

                                <li className="splide__slide">
                                    <a href="app-blog-post.html">
                                        <div className="blog-card">
                                            <img src={photo2} alt="image" className="imaged w-100" />
                                            <div className="text">
                                                <h4 className="title">Rules you need to know in business</h4>
                                            </div>
                                        </div>
                                    </a>
                                </li>

                                <li className="splide__slide">
                                    <a href="app-blog-post.html">
                                        <div className="blog-card">
                                            <img src={photo3} alt="image" className="imaged w-100" />
                                            <div className="text">
                                                <h4 className="title">10 easy ways to save your money</h4>
                                            </div>
                                        </div>
                                    </a>
                                </li>

                                <li className="splide__slide">
                                    <a href="app-blog-post.html">
                                        <div className="blog-card">
                                            <img src={photo4} alt="image" className="imaged w-100" />
                                            <div className="text">
                                                <h4 className="title">Follow the financial agenda with...</h4>
                                            </div>
                                        </div>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div> */}
                {/* <!-- * News --> */}


                {/* <!-- app footer --> */}
                <div className="appFooter">
                    <div className="footer-title">
                        Copyright © <a className="site-link" href='https://www.GetMubarak.com'>Mubarak</a> 2022. All Rights Reserved.
                    </div>
                    Interest Free Loans
                </div>
                {/* <!-- * app footer --> */}

            </div>
            {/* <!-- * App Capsule --> */}


            {/* <!-- App Bottom Menu --> */}
            <div className="appBottomMenu">
                <Link className="item active" to="/">
                    <div className="col">
                        <ion-icon name="pie-chart-outline"></ion-icon>
                        <strong>Overview</strong>
                    </div>
                </Link>
                <Link className="item" to="/transactions">
                    <div className="col">
                        <ion-icon name="document-text-outline"></ion-icon>
                        <strong>Transactions</strong>
                    </div>
                </Link>
                <Link className="item" to="/bills">
                    <div className="col">
                        <ion-icon name="apps-outline"></ion-icon>
                        <strong>Monthly Bills</strong>
                    </div>
                </Link>
                {/* <a href="app-cards.html" className="item">
                    <div className="col">
                        <ion-icon name="card-outline"></ion-icon>
                        <strong>My Cards</strong>
                    </div>
                </a> */}
                <Link className="item" to="/profile">
                    <div className="col">
                        <ion-icon name="settings-outline"></ion-icon>
                        <strong>Settings</strong>
                    </div>
                </Link>
            </div>
            {/* <!-- * App Bottom Menu --> */}

            {/* <!-- App Sidebar --> */}
            <div className="modal fade panelbox panelbox-left" id="sidebarPanel" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            {/* <!-- profile box --> */}
                            <div className="profileBox pt-2 pb-2">
                                <div className="image-wrapper">
                                    <img src={avatar1} alt="image" className="imaged  w36" />
                                </div>
                                <div className="in">
                                    <strong>{user ? user.displayName : "John Demo User"}</strong>
                                    <div className="text-muted">4029209</div>
                                </div>
                                <a href="#" className="btn btn-link btn-icon sidebar-close" data-bs-dismiss="modal">
                                    <ion-icon name="close-outline"></ion-icon>
                                </a>
                            </div>
                            {/* <!-- * profile box --> */}
                            {/* <!-- balance --> */}
                            <div className="sidebar-balance">
                                <div className="listview-title">Balance</div>
                                <div className="in">
                                    <h1 className="amount">$ 2,562.50</h1>
                                </div>
                            </div>
                            {/* <!-- * balance --> */}

                            {/* <!-- action group --> */}
                            {/* <div className="action-group">
                                <a href="index.html" className="action-button">
                                    <div className="in">
                                        <div className="iconbox">
                                            <ion-icon name="add-outline"></ion-icon>
                                        </div>
                                        Deposit
                                    </div>
                                </a>
                                <a href="index.html" className="action-button">
                                    <div className="in">
                                        <div className="iconbox">
                                            <ion-icon name="arrow-down-outline"></ion-icon>
                                        </div>
                                        Withdraw
                                    </div>
                                </a>
                                <a href="index.html" className="action-button">
                                    <div className="in">
                                        <div className="iconbox">
                                            <ion-icon name="arrow-forward-outline"></ion-icon>
                                        </div>
                                        Send
                                    </div>
                                </a>
                                <a href="app-cards.html" className="action-button">
                                    <div className="in">
                                        <div className="iconbox">
                                            <ion-icon name="card-outline"></ion-icon>
                                        </div>
                                        My Cards
                                    </div>
                                </a>
                            </div> */}
                            {/* <!-- * action group --> */}

                            {/* <!-- menu --> */}
                            <div className="listview-title mt-1">Menu</div>
                            <ul className="listview flush transparent no-line image-listview">
                                <li>
                                    <Link onClick={() => { document.querySelector('.modal-backdrop').remove(); document.querySelector('body').style={} }}to="/" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="pie-chart-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Overview
                                            {/* <span className="badge badge-primary">10</span> */}
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={() => { document.querySelector('.modal-backdrop').remove(); document.querySelector('body').style={} }}to="/transactions" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="document-text-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Transactions
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={() => { document.querySelector('.modal-backdrop').remove(); document.querySelector('body').style={} }}to="/bills" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="apps-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Monthly Bills
                                        </div>
                                    </Link>
                                </li>
                                {/* <li>
                                    <a href="app-cards.html" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="card-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            My Cards
                                        </div>
                                    </a>
                                </li> */}
                            </ul>
                            {/* <!-- * menu --> */}

                            {/* <!-- others --> */}
                            <div className="listview-title mt-1">Others</div>
                            <ul className="listview flush transparent no-line image-listview">
                                <li>
                                    <Link onClick={() => { document.querySelector('.modal-backdrop').remove(); document.querySelector('body').style={} }}to="/settings" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="settings-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Settings
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={() => { document.querySelector('.modal-backdrop').remove(); document.querySelector('body').style={} }}to="/support" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="chatbubble-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Support
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={() => { 
                                            document.querySelector('.modal-backdrop').remove();
                                            document.querySelector('body').style={};

                                            // user.logout()

                                            firebase.auth().signOut().then(() => {
                                                // alert("Successfully signed out")
                                            }).catch(() => {
                                                // alert("Error Signing Out")
                                            })







                                        }}to="/login" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="log-out-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Log out
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                            {/* <!-- * others --> */}

                            {/* <!-- send money --> */}
                            {/* <div className="listview-title mt-1">Send Money</div>
                            <ul className="listview image-listview flush transparent no-line">
                                <li>
                                    <a href="#" className="item">
                                        <img src={avatar2} alt="image" className="image" />
                                        <div className="in">
                                            <div>Artem Sazonov</div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="item">
                                        <img src={avatar4} alt="image" className="image" />
                                        <div className="in">
                                            <div>Sophie Asveld</div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="item">
                                        <img src={avatar3} alt="image" className="image" />
                                        <div className="in">
                                            <div>Kobus van de Vegte</div>
                                        </div>
                                    </a>
                                </li>
                            </ul> */}
                            {/* <!-- * send money --> */}

                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- * App Sidebar --> */}



            {/* <!-- iOS Add to Home Action Sheet --> */}
            <div className="modal inset fade action-sheet ios-add-to-home" id="ios-add-to-home-screen" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add to Home Screen</h5>
                            <a href="#" className="close-button" data-bs-dismiss="modal">
                                <ion-icon name="close"></ion-icon>
                            </a>
                        </div>
                        <div className="modal-body">
                            <div className="action-sheet-content text-center">
                                <div className="mb-1">
                                    <img src={imgX} alt="image" className="imaged w64 mb-2" />
                                </div>
                                <div>
                                    Install <strong>Mubarak</strong> on your iPhone's home screen.
                                </div>
                                <div>
                                    Tap <ion-icon name="share-outline"></ion-icon> and Add to homescreen.
                                </div>
                                <div className="mt-2">
                                    <button className="btn btn-primary btn-block" data-bs-dismiss="modal">CLOSE</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- * iOS Add to Home Action Sheet --> */}


            {/* <!-- Android Add to Home Action Sheet --> */}
            <div className="modal inset fade action-sheet android-add-to-home" id="android-add-to-home-screen" tabIndex="-1"
                role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add to Home Screen</h5>
                            <a href="#" className="close-button" data-bs-dismiss="modal">
                                <ion-icon name="close"></ion-icon>
                            </a>
                        </div>
                        <div className="modal-body">
                            <div className="action-sheet-content text-center">
                                <div className="mb-1">
                                    <img src={imgX} alt="image" className="imaged w64 mb-2" />
                                </div>
                                <div>
                                    Install <strong>Mubarak</strong> on your Android's home screen.
                                </div>
                                <div>
                                    Tap <ion-icon name="ellipsis-vertical"></ion-icon> and Add to homescreen.
                                </div>
                                <div className="mt-2">
                                    <button className="btn btn-primary btn-block" data-bs-dismiss="modal">CLOSE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- * Android Add to Home Action Sheet --> */}



            <div id="cookiesbox" className="offcanvas offcanvas-bottom cookies-box" tabIndex="-1" data-bs-scroll="true"
                data-bs-backdrop="false">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title">We use cookies</h5>
                </div>
                <div className="offcanvas-body">
                    <div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla non lacinia quam. Nulla facilisi.
                        <a href="#" className="text-secondary"><u>Learn more</u></a>
                    </div>
                    <div className="buttons">
                        <a href="#" className="btn btn-primary btn-block" data-bs-dismiss="offcanvas">I understand</a>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default App;
