import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useParams, Navigate } from "react-router-dom";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';


import useAuth, { AuthProvider } from "./useAuth";

import App from './App'; // TODO: Call this home not app
import Transactions from './Transactions';
import Transaction from './Transaction';
import Settings from './Settings';
import NotFound from './NotFound';
import Support from './Support';
import Login from './Login';
import Signup from './Signup';
import Bills from './Bills';
import Notifications from './Notifications';
import Notification from './Notification';

import reportWebVitals from './reportWebVitals';

// Routing Stuff
// console.log("WHAT IS WINDOW.LOCATION:", )
const IS_DEV = window.location.href.toLocaleLowerCase().includes("getmubarak.com") ? false : true

const BASE_URL_DEV = 'http://localhost:5000/'
const BASE_URL_PROD = 'https://app.getmubarak.com/'
// https://us-central1-financial-app-76849.cloudfunctions.net/app -> api.getmubarak.com
const BASE_URL = IS_DEV ? BASE_URL_DEV : BASE_URL_PROD


const LOGIN_URL = BASE_URL + "login/"


function RequireAuth({ children }) {
	const { authed, user } = useAuth();

    // console.log("RequireAuth({ children })", user)

    console.log("RequireAuth")
    // const [user, setUser] = useState(null);

    firebase.auth()

    useEffect(() => {
        console.log("RequireAuth useEffect")
        const authObserver = firebase.auth().onAuthStateChanged((user) => {
            // setUser(user)
            // alert(user)

            if (user == null) {
                // alert("please visit /login")
                setTimeout(() => {
                    window.location.href = LOGIN_URL
                }, 1000)
            }


        })
        console.log("RequireAuth useEffect user", user)
        console.log("RequireAuth useEffect firebase.auth.currentUser", user)
    //     return authObserver
    }, [])


    console.log("RequireAuth  authed", authed)
    console.log("RequireAuth  user", user)

    if (user) {
        return (children) 
    } else {
        // TODO: HOW TO DO THIS SAFELY?
        // The problem is that this happens async 
        // so the following code runs and doen't get user
        // How do I wait for response and then do something

        // return (<Navigate to="/login" replace />)
        return (<div />)
    }

    // return authed ? children :
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthProvider>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={
                    <RequireAuth><App /></RequireAuth>                } />
                <Route path="settings" element={
                    <RequireAuth><Settings /></RequireAuth>                } />
                <Route path="profile" element={
                    <RequireAuth><Settings /></RequireAuth>                } />
                <Route path="transaction" element={
                    <RequireAuth><Transaction /></RequireAuth>
                }>
                    <Route path=":transactionId" element={
                        <RequireAuth><Transaction /></RequireAuth>
                } />
                </Route>
                <Route path="transactions" element={
                    <RequireAuth><Transactions /></RequireAuth>                } />
                <Route path="bills" element={
                    <RequireAuth><Bills /></RequireAuth>                } />
                <Route path="notifications" element={
                    <RequireAuth><Notifications /></RequireAuth>                } />
                <Route path="notification" element={
                    <RequireAuth><Notification /></RequireAuth>                } />

                <Route path="login" element={<Login />} />
                <Route path="signup" element={<Signup />} />
                <Route path="support" element={<Support />} />
                <Route path="not-found" element={<NotFound />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
