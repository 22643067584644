import React, { useContext, createContext, useState, useEffect } from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';


const authContext = createContext();

const useAuth = () => {
    // const [user, setUser] = useState(null);
    // const [authed, setAuthed] = React.useState(false);


    // useEffect(() => {
    //     const authObserver = firebase.auth().onAuthStateChanged((user) => {
    //         setUser(user)
    //     })
    //     console.log("USE EFFECT2", firebase.auth().currentUser)
    //     console.log("USE EFFECT2.1", user)
    //     return authObserver
    // }, [])

    // console.log("USE EFFECT2.2", user)


    const [user, setUser] = useState(null);
    const [authed, setAuthed] = useState(null);

    console.log("useAuth")
    useEffect(() => {
        console.log("useAuth useEffect")
        const authObserver = firebase.auth().onAuthStateChanged((user) => {
            setUser(user)
        })
        console.log("USE EFFECT5", firebase.auth().currentUser)
        console.log("USE EFFECT5.1", user)

        return authObserver
    }, [])

    console.log("useAuth user", user)
    console.log("useAuth user.displayName", user?.displayName) // works
    console.log("useAuth user?.photoURL", user?.photoURL) // works
    console.log("useAuth authed", authed)

    // if (user) {
        console.log("useAuth w/ user", user)
        // console.log(user.displayName)
        // console.log(user.email)

        return {
            user,
            authed,
            login: () => {
                console.log("Logging In")
                setUser(user)
                alert(user)
            },
            signOut: () => {
                firebase.auth().signOut().then(() => {
                    setUser(null)
    
                    console.log("Successfully signed out")
                }).catch(() => {
    
                    console.log("Error Signing Out")
                })
            },
        };
    // } else {
    //     console.log("useAuth w/ no user")
    //     return null
    // }


}

export function AuthProvider({ children }) {
    const user = useAuth();

    return <authContext.Provider value={user}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
    return useContext(authContext);
}

