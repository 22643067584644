import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import useAuth from "./useAuth";

// import logo from './logo.svg';
import loadingIcon from './img/loading-icon.png'; // TODO:
// import avatar1 from './img/sample/avatar/avatar1.jpg';
import avatar1 from './img/sample/avatar/avatar-img-1.png';
import avatar2 from './img/sample/avatar/avatar2.jpg';
import avatar3 from './img/sample/avatar/avatar3.jpg';
import avatar4 from './img/sample/avatar/avatar4.jpg';
import avatar5 from './img/sample/avatar/avatar5.jpg';
import avatar6 from './img/sample/avatar/avatar6.jpg';
import avatar7 from './img/sample/avatar/avatar7.jpg';
import avatar8 from './img/sample/avatar/avatar8.jpg';
import avatar9 from './img/sample/avatar/avatar9.jpg';
import avatar10 from './img/sample/avatar/avatar10.jpg';
import brand0 from "./img/sample/brand/0.png";
import brand1 from "./img/sample/brand/1.jpg";
import brand2 from "./img/sample/brand/2.jpg";
import photo1 from './img/sample/photo/1.jpg';
import photo2 from './img/sample/photo/2.jpg';
import photo3 from './img/sample/photo/3.jpg';
import photo4 from './img/sample/photo/4.jpg';
import imgX from './img/icon/192x192.png';
// import './App.css'; // Leftover from create-react-app

import '@splidejs/react-splide/css';
import './bootstrap.min.css';
import './ionicons.min.css';
// import './splide.min.css';
import './index.css';

// Routing Stuff
const IS_DEV = true

const BASE_URL_DEV = 'http://localhost:5001/financial-app-76849/us-central1/app/'
const BASE_URL_PROD = ''
const BASE_URL = IS_DEV ? BASE_URL_DEV : BASE_URL_PROD

const TRANSACTIONS_URL = BASE_URL + "transactions/"


function Settings() {
    const { user } = useAuth();

    const [transactions, setTransactions] = useState([])

    useEffect(() => {
        // Fetch Transactions
        try {

            console.log("About to fetch transactions")
            fetch(TRANSACTIONS_URL)
            .then(res => {
                return res.json();
            })
            .then(data => {
                console.log("transactions/ endpoint")
                console.log(data)
                setTransactions(data['data'])
            });
        } catch {
            console.log("!!! Failed to get transactions")
        }
    }, [])

    return (
        <div className="body">
            {/* <!-- loader --> */}
            <div id="loader">
                <img src={loadingIcon} alt="icon" className="loading-icon" onLoad={() => { 
                    setTimeout(() => {
                    document.getElementById('loader').remove()
                    }, 1500) 
                }} />
            </div>
            {/* <!-- * loader --> */}

            {/* <!-- App Header --> */}
            <div className="appHeader bg-primary text-light">
                <div className="left">
                    <a href="#" className="headerButton" data-bs-toggle="modal" data-bs-target="#sidebarPanel">
                        <ion-icon name="menu-outline"></ion-icon>
                    </a>
                </div>
                <div className="pageTitle">
                    {/* <a className="mubarak-logo-text">Mubarak</a> */}
                    <Link className="mubarak-logo-text" to="/">
                        <a className="mubarak-logo-text">Mubarak</a>
                    </Link>
                </div>
                <div className="right">
                    {/* <Link className='headerButton' to="/notifications">
                        <ion-icon className="icon" name="notifications-outline"></ion-icon> */}
                        {/* <span className="badge badge-danger">4</span> */}
                    {/* </Link> */}
                    <Link className='link profile' to="/profile">
                        <img src={avatar1} alt="image" className="imaged w32" />
                        {/* <img src={user?.photoURL ? user?.photoURL : avatar1} alt="image" className="imaged w32" /> */}
                        {/* <span className="badge badge-danger">6</span> */}
                    </Link>
                </div>
            </div>
            {/* <!-- * App Header --> */}


            {/* <!-- App Capsule --> */}
            <div id="appCapsule">
                <div class="section mt-3 text-center">
                    <div class="avatar-section">
                        <a href="#">
                            <img src={avatar1} alt="avatar" class="imaged w100 rounded" />
                            <span class="button">
                                <ion-icon name="camera-outline"></ion-icon>
                            </span>
                        </a>
                    </div>
                </div>

                <div class="listview-title mt-1">Theme</div>
                <ul class="listview image-listview text inset no-line">
                    <li>
                        <div class="item">
                            <div class="in">
                                <div>
                                    Dark Mode
                                </div>
                                <div class="form-check form-switch  ms-2">
                                    <input class="form-check-input dark-mode-switch" type="checkbox" id="darkmodeSwitch" />
                                    <label class="form-check-label" for="darkmodeSwitch"></label>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <div class="listview-title mt-1">Notifications</div>
                <ul class="listview image-listview text inset">
                    <li>
                        <div class="item">
                            <div class="in">
                                <div>
                                    Payment Alert
                                    <div class="text-muted">
                                        Send notification when new payment received
                                    </div>
                                </div>
                                <div class="form-check form-switch  ms-2">
                                    <input class="form-check-input" type="checkbox" id="SwitchCheckDefault1" />
                                    <label class="form-check-label" for="SwitchCheckDefault1"></label>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="#" class="item">
                            <div class="in">
                                <div>Notification Sound</div>
                                <span class="text-primary">Beep</span>
                            </div>
                        </a>
                    </li>
                </ul>

                <div class="listview-title mt-1">Profile Settings</div>
                <ul class="listview image-listview text inset">
                    <li>
                        <a href="#" class="item">
                            <div class="in">
                                <div>Change Username</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item">
                            <div class="in">
                                <div>Update E-mail</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item">
                            <div class="in">
                                <div>Address</div>
                                <span class="text-primary">Edit</span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <div class="item">
                            <div class="in">
                                <div>
                                    Private Profile
                                </div>
                                <div class="form-check form-switch ms-2">
                                    <input class="form-check-input" type="checkbox" id="SwitchCheckDefault2" />
                                    <label class="form-check-label" for="SwitchCheckDefault2"></label>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <div class="listview-title mt-1">Security</div>
                <ul class="listview image-listview text mb-2 inset">
                    <li>
                        <a href="#" class="item">
                            <div class="in">
                                <div>Update Password</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <div class="item">
                            <div class="in">
                                <div>
                                    2 Step Verification
                                </div>
                                <div class="form-check form-switch ms-2">
                                    <input class="form-check-input" type="checkbox" id="SwitchCheckDefault3" checked />
                                    <label class="form-check-label" for="SwitchCheckDefault3"></label>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="#" class="item">
                            <div class="in">
                                <div>Log out all devices</div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            {/* <!-- * App Capsule --> */}


            {/* <!-- App Bottom Menu --> */}
            <div className="appBottomMenu">
                <Link className="item" to="/">
                    <div className="col">
                        <ion-icon name="pie-chart-outline"></ion-icon>
                        <strong>Overview</strong>
                    </div>
                </Link>
                <Link className="item" to="/transactions">
                    <div className="col">
                        <ion-icon name="document-text-outline"></ion-icon>
                        <strong>Transactions</strong>
                    </div>
                </Link>
                <Link className="item" to="/bills">
                    <div className="col">
                        <ion-icon name="apps-outline"></ion-icon>
                        <strong>Monthly Bills</strong>
                    </div>
                </Link>
                {/* <a href="app-cards.html" className="item">
                    <div className="col">
                        <ion-icon name="card-outline"></ion-icon>
                        <strong>My Cards</strong>
                    </div>
                </a> */}
                <Link className="item active" to="/profile">
                    <div className="col">
                        <ion-icon name="settings-outline"></ion-icon>
                        <strong>Settings</strong>
                    </div>
                </Link>
            </div>
            {/* <!-- * App Bottom Menu --> */}

            {/* <!-- App Sidebar --> */}
            <div className="modal fade panelbox panelbox-left" id="sidebarPanel" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            {/* <!-- profile box --> */}
                            <div className="profileBox pt-2 pb-2">
                                <div className="image-wrapper">
                                    <img src={avatar1} alt="image" className="imaged  w36" />
                                </div>
                                <div className="in">
                                    <strong>{user ? user.displayName : "John Doe"}</strong>
                                    <div className="text-muted">4029209</div>
                                </div>
                                <a href="#" className="btn btn-link btn-icon sidebar-close" data-bs-dismiss="modal">
                                    <ion-icon name="close-outline"></ion-icon>
                                </a>
                            </div>
                            {/* <!-- * profile box --> */}
                            {/* <!-- balance --> */}
                            <div className="sidebar-balance">
                                <div className="listview-title">Balance</div>
                                <div className="in">
                                    <h1 className="amount">$ 2,562.50</h1>
                                </div>
                            </div>
                            {/* <!-- * balance --> */}

                            {/* <!-- action group --> */}
                            {/* <div className="action-group">
                                <a href="index.html" className="action-button">
                                    <div className="in">
                                        <div className="iconbox">
                                            <ion-icon name="add-outline"></ion-icon>
                                        </div>
                                        Deposit
                                    </div>
                                </a>
                                <a href="index.html" className="action-button">
                                    <div className="in">
                                        <div className="iconbox">
                                            <ion-icon name="arrow-down-outline"></ion-icon>
                                        </div>
                                        Withdraw
                                    </div>
                                </a>
                                <a href="index.html" className="action-button">
                                    <div className="in">
                                        <div className="iconbox">
                                            <ion-icon name="arrow-forward-outline"></ion-icon>
                                        </div>
                                        Send
                                    </div>
                                </a>
                                <a href="app-cards.html" className="action-button">
                                    <div className="in">
                                        <div className="iconbox">
                                            <ion-icon name="card-outline"></ion-icon>
                                        </div>
                                        My Cards
                                    </div>
                                </a>
                            </div> */}
                            {/* <!-- * action group --> */}

                            {/* <!-- menu --> */}
                            <div className="listview-title mt-1">Menu</div>
                            <ul className="listview flush transparent no-line image-listview">
                                <li>
                                    <Link onClick={() => { document.querySelector('.modal-backdrop').remove(); document.querySelector('body').style={} }}to="/" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="pie-chart-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Overview
                                            {/* <span className="badge badge-primary">10</span> */}
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={() => { document.querySelector('.modal-backdrop').remove(); document.querySelector('body').style={} }}to="/transactions" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="document-text-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Transactions
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={() => { document.querySelector('.modal-backdrop').remove(); document.querySelector('body').style={} }}to="/bills" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="apps-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Monthly Bills
                                        </div>
                                    </Link>
                                </li>
                                {/* <li>
                                    <a href="app-cards.html" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="card-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            My Cards
                                        </div>
                                    </a>
                                </li> */}
                            </ul>
                            {/* <!-- * menu --> */}

                            {/* <!-- others --> */}
                            <div className="listview-title mt-1">Others</div>
                            <ul className="listview flush transparent no-line image-listview">
                                <li>
                                    <Link onClick={() => { document.querySelector('.modal-backdrop').remove(); document.querySelector('body').style={} }}to="/settings" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="settings-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Settings
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={() => { document.querySelector('.modal-backdrop').remove(); document.querySelector('body').style={} }}to="/support" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="chatbubble-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Support
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={() => { document.querySelector('.modal-backdrop').remove(); document.querySelector('body').style={} }}to="/login" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="log-out-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Log out
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                            {/* <!-- * others --> */}

                            {/* <!-- send money --> */}
                            {/* <div className="listview-title mt-1">Send Money</div>
                            <ul className="listview image-listview flush transparent no-line">
                                <li>
                                    <a href="#" className="item">
                                        <img src={avatar2} alt="image" className="image" />
                                        <div className="in">
                                            <div>Artem Sazonov</div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="item">
                                        <img src={avatar4} alt="image" className="image" />
                                        <div className="in">
                                            <div>Sophie Asveld</div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="item">
                                        <img src={avatar3} alt="image" className="image" />
                                        <div className="in">
                                            <div>Kobus van de Vegte</div>
                                        </div>
                                    </a>
                                </li>
                            </ul> */}
                            {/* <!-- * send money --> */}

                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- * App Sidebar --> */}



            {/* <!-- iOS Add to Home Action Sheet --> */}
            <div className="modal inset fade action-sheet ios-add-to-home" id="ios-add-to-home-screen" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add to Home Screen</h5>
                            <a href="#" className="close-button" data-bs-dismiss="modal">
                                <ion-icon name="close"></ion-icon>
                            </a>
                        </div>
                        <div className="modal-body">
                            <div className="action-sheet-content text-center">
                                <div className="mb-1">
                                    <img src={imgX} alt="image" className="imaged w64 mb-2" />
                                </div>
                                <div>
                                    Install <strong>Mubarak</strong> on your iPhone's home screen.
                                </div>
                                <div>
                                    Tap <ion-icon name="share-outline"></ion-icon> and Add to homescreen.
                                </div>
                                <div className="mt-2">
                                    <button className="btn btn-primary btn-block" data-bs-dismiss="modal">CLOSE</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- * iOS Add to Home Action Sheet --> */}


            {/* <!-- Android Add to Home Action Sheet --> */}
            <div className="modal inset fade action-sheet android-add-to-home" id="android-add-to-home-screen" tabIndex="-1"
                role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add to Home Screen</h5>
                            <a href="#" className="close-button" data-bs-dismiss="modal">
                                <ion-icon name="close"></ion-icon>
                            </a>
                        </div>
                        <div className="modal-body">
                            <div className="action-sheet-content text-center">
                                <div className="mb-1">
                                    <img src={imgX} alt="image" className="imaged w64 mb-2" />
                                </div>
                                <div>
                                    Install <strong>Mubarak</strong> on your Android's home screen.
                                </div>
                                <div>
                                    Tap <ion-icon name="ellipsis-vertical"></ion-icon> and Add to homescreen.
                                </div>
                                <div className="mt-2">
                                    <button className="btn btn-primary btn-block" data-bs-dismiss="modal">CLOSE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- * Android Add to Home Action Sheet --> */}



            <div id="cookiesbox" className="offcanvas offcanvas-bottom cookies-box" tabIndex="-1" data-bs-scroll="true"
                data-bs-backdrop="false">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title">We use cookies</h5>
                </div>
                <div className="offcanvas-body">
                    <div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla non lacinia quam. Nulla facilisi.
                        <a href="#" className="text-secondary"><u>Learn more</u></a>
                    </div>
                    <div className="buttons">
                        <a href="#" className="btn btn-primary btn-block" data-bs-dismiss="offcanvas">I understand</a>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Settings;
