import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import useAuth from "./useAuth";


// import logo from './logo.svg';
import loadingIcon from './img/loading-icon.png'; // TODO:
// import avatar1 from './img/sample/avatar/avatar1.jpg';
import avatar1 from './img/sample/avatar/avatar-img-1.png';
import avatar2 from './img/sample/avatar/avatar2.jpg';
import avatar3 from './img/sample/avatar/avatar3.jpg';
import avatar4 from './img/sample/avatar/avatar4.jpg';
import brand0 from "./img/sample/brand/0.png";
import imgX from './img/icon/192x192.png';
// import './App.css'; // Leftover from create-react-app

import appStore from "./img/sample/brand/app-store-transaction.png";
import card from "./img/sample/brand/card.png";
import food from "./img/sample/brand/food.png";
import sendMoney from "./img/sample/brand/send-money.png";
import shopping from "./img/sample/brand/shopping.png";
import transfer from "./img/sample/brand/transfer.png";
import travel from "./img/sample/brand/travel1.png";
import taxi from "./img/sample/brand/taxi.png";
import health from "./img/sample/brand/health.png";
import netflix from "./img/sample/brand/netflix.png";
import spotify from "./img/sample/brand/spotify.png";


import '@splidejs/react-splide/css';
import './bootstrap.min.css';
import './ionicons.min.css';
// import './splide.min.css';
import './index.css';


const moment = require('moment');

// Routing Stuff
const IS_DEV = window.location.href.toLocaleLowerCase().includes("getmubarak.com") ? false : true

const BASE_URL_DEV = 'http://localhost:5000/'
const BASE_URL_PROD = 'https://app.getMubarak.com/'
const BASE_URL = IS_DEV ? BASE_URL_DEV : BASE_URL_PROD

const BASE_URL_API_DEV = 'http://localhost:5001/financial-app-76849/us-central1/app/'
const BASE_URL_API_PROD = 'https://us-central1-financial-app-76849.cloudfunctions.net/app/'
const BASE_URL_API = IS_DEV ? BASE_URL_API_DEV : BASE_URL_API_PROD

const TRANSACTIONS_URL = BASE_URL_API + "transactions2/"
// const TRANSACTIONS_URL = BASE_URL + "transactions2/"


// Helpers
const CURRENCY_CODE_SYMBOL_MAP = {
    "USD": "$"
}


function Transactions() {
    const { user } = useAuth();

    const [transactions, setTransactions] = useState([])

    useEffect(() => {
        // Fetch Transactions
        try {

            console.log("About to fetch transactions")
            fetch(TRANSACTIONS_URL)
            .then(res => {
                return res.json();
            })
            .then(data => {
                console.log("transactions/ endpoint")
                console.log(data)
                setTransactions(data['data'])
            });
        } catch {
            console.log("!!! Failed to get transactions")
        }
    }, [])

    const getCategoryIconFromCategoryList = (categoryList) => {
        if (categoryList.includes("Travel") && categoryList.includes("Airlines and Aviation Services")) {
            return travel
        }
        else if (categoryList.includes("Travel") && categoryList.includes("Taxi")) {
            return taxi
        }
        else if (categoryList.includes("Payment")) {
            return card
        }
        else if (categoryList.includes("Transfer")) {
            return sendMoney
        }
        else if (categoryList.includes("Food and Drink")) {
            return food
        }
        // else if (categoryList.includes("Travel")) {
        //     return ("airplace")
        // }
        // else if (categoryList.includes("Travel")) {
        //     return ("airplace")
        // }
        // else if (categoryList.includes("Travel")) {
        //     return ("airplace")
        // } 
        else {
            return brand0
        }
    }

    console.log("Transactions*** ", transactions)
    const dateGroups = transactions.map((transaction) => {return [transaction['date'], transaction['transaction_id']]}).reduce((acc, pair) => {
        var date = pair[0]
        var transaction_id = pair[1]

        // create a composed key: 'year-week' 
        const yearWeek = `${moment(date).year()}-${moment(date).week()}`;
        
        // add this key as a property to the result object
        if (!acc[yearWeek]) {
          acc[yearWeek] = [];
        }
        
        // push the current date that belongs to the year-week calculated befor
        acc[yearWeek].push([date, transaction_id]);
      
        return acc;
      
    }, {})
      
    // console.log("dateGroups, ", dateGroups);
    // console.log("Object.keys(dateGroups).sort().reverse(), ", Object.keys(dateGroups).sort((a, b) => {
    //     return parseInt(b[0].split("-")[1]) - parseInt(a[0].split("-")[1])
    // }));

    const weekAgo = (tmpDate) => {
        // tmpDate += "-" + now.day()
        let date = moment(tmpDate, "YYYY-W", true)
        console.log(">>>> tempdate", tmpDate)
        console.log(">>>> date", date)
        var now = moment()
        var currWeek = moment().format("YYYY-W").split("-")[1]
        console.log(">>>> currWeek", currWeek)
        console.log(">>>> now", now)
        var days = now.diff(date, "days")
        console.log(">>>> days", days)
        var weeks = Math.abs(date.diff(now, "weeks"))
        console.log(">>>> weeks", weeks)
        var result = "";
  
        if (weeks == 0) {
            result = ""
        } else {
            result += (3 + weeks) === 1 ? "This week" : (weeks + " weeks ago");
            days = days % 7;
        }
    
        return result;
  
    }
      
    return (
        <div className="body">
            {/* <!-- loader --> */}
            <div id="loader">
                <img src={loadingIcon} alt="icon" className="loading-icon" onLoad={() => { 
                    setTimeout(() => {
                    document.getElementById('loader').remove()
                    }, 1500) 
                }} />
            </div>
            {/* <!-- * loader --> */}

            {/* <!-- App Header --> */}
            <div className="appHeader bg-primary text-light">
                <div className="left">
                    <a href="#" className="headerButton" data-bs-toggle="modal" data-bs-target="#sidebarPanel">
                        <ion-icon name="menu-outline"></ion-icon>
                    </a>
                </div>
                <div className="pageTitle">
                    {/* <a className="mubarak-logo-text">Mubarak</a> */}
                    <Link className="mubarak-logo-text" to="/">
                        <a className="mubarak-logo-text">Mubarak</a>
                    </Link>
                </div>
                <div className="right">
                    {/* <Link className='headerButton' to="/notifications">
                        <ion-icon className="icon" name="notifications-outline"></ion-icon> */}
                        {/* <span className="badge badge-danger">4</span> */}
                    {/* </Link> */}
                    <Link className='link profile' to="/profile">
                        <img src={avatar1} alt="image" className="imaged w32" />
                        {/* <img src={user?.photoURL ? user?.photoURL : avatar1} alt="image" className="imaged w32" /> */}
                        {/* <span className="badge badge-danger">6</span> */}
                    </Link>
                </div>
            </div>
            {/* <!-- * App Header --> */}


            {/* <!-- App Capsule --> */}
            <div id="appCapsule">


                {/* <!-- Transactions --> */}
                <div class="section mt-2">
                    {dateGroups && Object.keys(dateGroups).sort((a, b) => {
                        return parseInt(b[0].split("-")[1]) - parseInt(a[0].split("-")[1])
                    }).map((weekGroup) => {
                        return (
                        <div>                            
                            <div class="section-title">{weekAgo(weekGroup) == "" ? "" : weekAgo(weekGroup) + " - " +  moment(weekGroup, "YYYY-W").format("MMM Do YYYY")}</div>
                            <div class="transactions">
                                {dateGroups[weekGroup].sort().map((pair) => {
                                    console.log("**((((**(((**(( pair", pair)
                                    var transaction_id = pair[1]
                                    var transaction = transactions.filter((tmpTransaction) => {
                                        return tmpTransaction["transaction_id"] == transaction_id

                                    })[0]

                                    console.log("!!!! ", transaction)
                                    return (
                                        <Link className="item" to={`/transaction/${transaction['transaction_id']}`} key={'transactions-key'}>
                                            <div className="detail">
                                                <img src={getCategoryIconFromCategoryList(transaction['category'])} alt="img" className="image-block imaged w48" />
                                                <div>
                                                    <strong>
                                                        {transaction['name']}
                                                    </strong>
                                                    <p>
                                                        {transaction['category'].length > 0 ? transaction['category'][0] : ""}
                                                    </p>
                                                    <p>
                                                        {transaction['date'].length > 0 ? transaction['date'] : ""}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="right">
                                                <div className={`price ${transaction['amount'] > 0 ? 'text-danger' : 'text-success'}`}>
                                                    {CURRENCY_CODE_SYMBOL_MAP[transaction['iso_currency_code']]}
                                                    {-1 * transaction['amount']}
                                                </div>
                                            </div>
                                        </Link>
                                    )}) // transactions.splice(1,90).map((transaction) => {
                                }
                            </div>
                        </div>
                        )

                    })
                    }
                    </div>
                {/* <!-- * Transactions --> */}

                {/* <!-- * Transactions --> */}

                <div class="section mt-2 mb-2">
                    <a href="#" class="btn btn-primary btn-block btn-lg">Load More</a>
                </div>
            </div>
            {/* <!-- * App Capsule --> */}


            {/* <!-- App Bottom Menu --> */}
            <div className="appBottomMenu">
                <Link className="item" to="/">
                    <div className="col">
                        <ion-icon name="pie-chart-outline"></ion-icon>
                        <strong>Overview</strong>
                    </div>
                </Link>
                <Link className="item active" to="/transactions">
                    <div className="col">
                        <ion-icon name="document-text-outline"></ion-icon>
                        <strong>Transactions</strong>
                    </div>
                </Link>
                <Link className="item" to="/bills">
                    <div className="col">
                        <ion-icon name="apps-outline"></ion-icon>
                        <strong>Monthly Bills</strong>
                    </div>
                </Link>
                {/* <a href="app-cards.html" className="item">
                    <div className="col">
                        <ion-icon name="card-outline"></ion-icon>
                        <strong>My Cards</strong>
                    </div>
                </a> */}
                <Link className="item" to="/profile">
                    <div className="col">
                        <ion-icon name="settings-outline"></ion-icon>
                        <strong>Settings</strong>
                    </div>
                </Link>
            </div>
            {/* <!-- * App Bottom Menu --> */}

            {/* <!-- App Sidebar --> */}
            <div className="modal fade panelbox panelbox-left" id="sidebarPanel" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            {/* <!-- profile box --> */}
                            <div className="profileBox pt-2 pb-2">
                                <div className="image-wrapper">
                                    <img src={avatar1} alt="image" className="imaged  w36" />
                                </div>
                                <div className="in">
                                    <strong>{user ? user.displayName : "John Doe"}</strong>
                                    <div className="text-muted">4029209</div>
                                </div>
                                <a href="#" className="btn btn-link btn-icon sidebar-close" data-bs-dismiss="modal">
                                    <ion-icon name="close-outline"></ion-icon>
                                </a>
                            </div>
                            {/* <!-- * profile box --> */}
                            {/* <!-- balance --> */}
                            <div className="sidebar-balance">
                                <div className="listview-title">Balance</div>
                                <div className="in">
                                    <h1 className="amount">$ 2,562.50</h1>
                                </div>
                            </div>
                            {/* <!-- * balance --> */}

                            {/* <!-- action group --> */}
                            {/* <div className="action-group">
                                <a href="index.html" className="action-button">
                                    <div className="in">
                                        <div className="iconbox">
                                            <ion-icon name="add-outline"></ion-icon>
                                        </div>
                                        Deposit
                                    </div>
                                </a>
                                <a href="index.html" className="action-button">
                                    <div className="in">
                                        <div className="iconbox">
                                            <ion-icon name="arrow-down-outline"></ion-icon>
                                        </div>
                                        Withdraw
                                    </div>
                                </a>
                                <a href="index.html" className="action-button">
                                    <div className="in">
                                        <div className="iconbox">
                                            <ion-icon name="arrow-forward-outline"></ion-icon>
                                        </div>
                                        Send
                                    </div>
                                </a>
                                <a href="app-cards.html" className="action-button">
                                    <div className="in">
                                        <div className="iconbox">
                                            <ion-icon name="card-outline"></ion-icon>
                                        </div>
                                        My Cards
                                    </div>
                                </a>
                            </div> */}
                            {/* <!-- * action group --> */}

                            {/* <!-- menu --> */}
                            <div className="listview-title mt-1">Menu</div>
                            <ul className="listview flush transparent no-line image-listview">
                                <li>
                                    <Link onClick={() => { document.querySelector('.modal-backdrop').remove(); document.querySelector('body').style={} }}to="/" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="pie-chart-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Overview
                                            {/* <span className="badge badge-primary">10</span> */}
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={() => { document.querySelector('.modal-backdrop').remove(); document.querySelector('body').style={} }}to="/transactions" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="document-text-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Transactions
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={() => { document.querySelector('.modal-backdrop').remove(); document.querySelector('body').style={} }}to="/bills" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="apps-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Monthly Bills
                                        </div>
                                    </Link>
                                </li>
                                {/* <li>
                                    <a href="app-cards.html" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="card-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            My Cards
                                        </div>
                                    </a>
                                </li> */}
                            </ul>
                            {/* <!-- * menu --> */}

                            {/* <!-- others --> */}
                            <div className="listview-title mt-1">Others</div>
                            <ul className="listview flush transparent no-line image-listview">
                                <li>
                                    <Link onClick={() => { document.querySelector('.modal-backdrop').remove(); document.querySelector('body').style={} }}to="/settings" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="settings-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Settings
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={() => { document.querySelector('.modal-backdrop').remove(); document.querySelector('body').style={} }}to="/support" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="chatbubble-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Support
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={() => { document.querySelector('.modal-backdrop').remove(); document.querySelector('body').style={} }}to="/login" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="log-out-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Log out
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                            {/* <!-- * others --> */}

                            {/* <!-- send money --> */}
                            {/* <div className="listview-title mt-1">Send Money</div>
                            <ul className="listview image-listview flush transparent no-line">
                                <li>
                                    <a href="#" className="item">
                                        <img src={avatar2} alt="image" className="image" />
                                        <div className="in">
                                            <div>Artem Sazonov</div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="item">
                                        <img src={avatar4} alt="image" className="image" />
                                        <div className="in">
                                            <div>Sophie Asveld</div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="item">
                                        <img src={avatar3} alt="image" className="image" />
                                        <div className="in">
                                            <div>Kobus van de Vegte</div>
                                        </div>
                                    </a>
                                </li>
                            </ul> */}
                            {/* <!-- * send money --> */}

                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- * App Sidebar --> */}



            {/* <!-- iOS Add to Home Action Sheet --> */}
            <div className="modal inset fade action-sheet ios-add-to-home" id="ios-add-to-home-screen" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add to Home Screen</h5>
                            <a href="#" className="close-button" data-bs-dismiss="modal">
                                <ion-icon name="close"></ion-icon>
                            </a>
                        </div>
                        <div className="modal-body">
                            <div className="action-sheet-content text-center">
                                <div className="mb-1">
                                    <img src={imgX} alt="image" className="imaged w64 mb-2" />
                                </div>
                                <div>
                                    Install <strong>Mubarak</strong> on your iPhone's home screen.
                                </div>
                                <div>
                                    Tap <ion-icon name="share-outline"></ion-icon> and Add to homescreen.
                                </div>
                                <div className="mt-2">
                                    <button className="btn btn-primary btn-block" data-bs-dismiss="modal">CLOSE</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- * iOS Add to Home Action Sheet --> */}


            {/* <!-- Android Add to Home Action Sheet --> */}
            <div className="modal inset fade action-sheet android-add-to-home" id="android-add-to-home-screen" tabIndex="-1"
                role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add to Home Screen</h5>
                            <a href="#" className="close-button" data-bs-dismiss="modal">
                                <ion-icon name="close"></ion-icon>
                            </a>
                        </div>
                        <div className="modal-body">
                            <div className="action-sheet-content text-center">
                                <div className="mb-1">
                                    <img src={imgX} alt="image" className="imaged w64 mb-2" />
                                </div>
                                <div>
                                    Install <strong>Mubarak</strong> on your Android's home screen.
                                </div>
                                <div>
                                    Tap <ion-icon name="ellipsis-vertical"></ion-icon> and Add to homescreen.
                                </div>
                                <div className="mt-2">
                                    <button className="btn btn-primary btn-block" data-bs-dismiss="modal">CLOSE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- * Android Add to Home Action Sheet --> */}



            <div id="cookiesbox" className="offcanvas offcanvas-bottom cookies-box" tabIndex="-1" data-bs-scroll="true"
                data-bs-backdrop="false">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title">We use cookies</h5>
                </div>
                <div className="offcanvas-body">
                    <div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla non lacinia quam. Nulla facilisi.
                        <a href="#" className="text-secondary"><u>Learn more</u></a>
                    </div>
                    <div className="buttons">
                        <a href="#" className="btn btn-primary btn-block" data-bs-dismiss="offcanvas">I understand</a>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Transactions;
